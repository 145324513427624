const Enumify = require('enumify').Enumify
/*
 * 编译环境
 * */
class ProjectLevelEnum extends Enumify {
  static MAIN = new ProjectLevelEnum({
    key: 'main',
    name: '主应用',
  })
  static MICRO = new ProjectLevelEnum({
    key: 'micro',
    name: '子应用',
  })

  static LAYOUT = new ProjectLevelEnum({
    key: 'layout',
    name: '布局应用',
  })

  static FRAMEWORK = new ProjectLevelEnum({
    key: 'framework',
    name: '框架应用',
  })

  static COMPONENT = new ProjectLevelEnum({
    key: 'component',
    name: '组件应用',
  })

  static BUSINESS = new ProjectLevelEnum({
    key: 'business',
    name: '业务应用',
  })

  static BASICS = new ProjectLevelEnum({
    key: 'basics',
    name: '基础应用',
  })
  static _ = this.closeEnum() // TypeScript: Color.closeEnum()
  constructor(props) {
    super()
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props))
  }
  static getLevelByProjectName(projectName) {
    let level = ''
    if (projectName) {
      if (projectName.includes(ProjectLevelEnum.MAIN.key)) {
        level = ProjectLevelEnum.MAIN.key
      } else if (projectName.includes(ProjectLevelEnum.MICRO.key)) {
        level = ProjectLevelEnum.MICRO.key
      } else if (projectName.includes(ProjectLevelEnum.FRAMEWORK.key)) {
        level = ProjectLevelEnum.FRAMEWORK.key
      } else if (projectName.includes(ProjectLevelEnum.LAYOUT.key)) {
        level = ProjectLevelEnum.LAYOUT.key
      } else if (projectName.includes(ProjectLevelEnum.COMPONENT.key)) {
        level = ProjectLevelEnum.COMPONENT.key
      } else if (projectName.includes(ProjectLevelEnum.BUSINESS.key)) {
        level = ProjectLevelEnum.BUSINESS.key
      } else if (projectName.includes(ProjectLevelEnum.BASICS.key)) {
        level = ProjectLevelEnum.BASICS.key
      }
    }
    return level
  }
}

module.exports = ProjectLevelEnum
