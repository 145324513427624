const config = {
  ORANGE_BANK: {
    URL: 'https://my-st1.orangebank.com.cn',
    SUPERVISED_ACCOUNT: '15000144828257',
  },
  WX: {
    APP_ID: 'wx80c0ed5a05c6eb1a',
    SECRET: '31b25dad92ab609eaadb1429cb0a96a1',
  },
  miniApp: {
    openPage: '/pages/index/open/link?',
    reverseProxyUrl: 'https://fadadadm.jinsubao.cn',
  },
  app: {
    messageKey: 'jinsubao://app.cn/open?',
    itunes: 'itms-apps://itunes.apple.com/cn/app/id1571060203',
    ios: {
      portalKey: 'itms-apps://itunes.apple.com/cn/app/id1571060203',
      portalUrl: 'https://apps.apple.com/cn/app/id1571060203',
      sellerKey: 'itms-apps://itunes.apple.com/cn/app/id1636436434',
      sellerUrl: 'https://apps.apple.com/cn/app/id1636436434',
    },
    seller: {
      messageKey: 'jsbseller://app.cn/open?',
    },
  },
}
module.exports = config

// 服务号 APPID：wx80c0ed5a05c6eb1a
// 小程序（正） APPID：wxaa9921a7850c45b0
// 小程序（测） APPID：wx012cdee3e704ce9b
