const Enumify = require('enumify').Enumify
const commonConfig = require('./commonConfig')
const merge = require('lodash/merge')
/*
 * 公共环境枚举
 * */
class CommonEnvEnum extends Enumify {
  static LOCAL = new CommonEnvEnum({
    key: 'local',
    name: '本地',
    address: 'localhost', //192.168.101.121  别人访问我们的本地 这里改成自己的ip  localhost
    protocol: 'http://',
  })
  static PRODUCTION_DEV = new CommonEnvEnum({
    key: 'dev',
    name: '开发',
    address: '192.168.101.28',
    protocol: 'http://',
    config: merge({}, commonConfig, {
      IM_KEY: 'cpj2xarlchk7n',
    }),
  })
  static PRODUCTION_TEST = new CommonEnvEnum({
    key: 'test',
    name: '测试',
    address: '192.168.101.24',
    protocol: 'http://',
    config: merge({}, commonConfig, {
      IM_KEY: '3argexb63fube',
    }),
  })
  static PRODUCTION_PREVIEW = new CommonEnvEnum({
    key: 'preview',
    name: '预览',
    address: 'jinsubao.cn',
    protocol: 'https://',
    config: merge({}, commonConfig, {
      IM_KEY: 'pvxdm17jpd2gr',
    }),
  })
  static PRODUCTION_PROD = new CommonEnvEnum({
    key: 'prod',
    name: '生产',
    address: 'jinsubao.cn',
    protocol: 'https://',
    config: merge({}, commonConfig, {
      IM_KEY: 'pgyu6atqpmheu',
      ORANGE_BANK: {
        URL: 'https://my.orangebank.com.cn',
        SUPERVISED_ACCOUNT: '15424788990099',
      },
      miniApp: {
        reverseProxyUrl: 'https://fadada.jinsubao.cn',
      },
    }),
  })
  static _ = this.closeEnum() // TypeScript: Color.closeEnum()
  constructor(props) {
    super()
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props))
  }
  static getProductionEnvList() {
    return [
      CommonEnvEnum.PRODUCTION_DEV.key,
      CommonEnvEnum.PRODUCTION_TEST.key,
      CommonEnvEnum.PRODUCTION_PREVIEW.key,
      CommonEnvEnum.PRODUCTION_PROD.key,
    ]
  }
}

module.exports = CommonEnvEnum
