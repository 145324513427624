const Enumify = require('enumify').Enumify
/*
* production
development
* */
class CompileModeEnum extends Enumify {
  static DEVELOPMENT = new CompileModeEnum({ key: 'development', name: '开发' })
  static PRODUCTION = new CompileModeEnum({ key: 'production', name: '生产' })
  static _ = this.closeEnum() // TypeScript: Color.closeEnum()
  constructor(props) {
    super()
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props))
  }
}

module.exports = CompileModeEnum
