const Enumify = require('enumify').Enumify
const CommonEnvEnum = require('../common/CommonEnvEnum')
const CompileModeEnum = require('../common/CompileModeEnum')

class ModuleFederationServerEnum extends Enumify {
  static LOCAL = new ModuleFederationServerEnum({
    key: CommonEnvEnum.LOCAL.key,
    url: CommonEnvEnum.LOCAL.protocol + CommonEnvEnum.LOCAL.address,
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static PRODUCTION_DEV = new ModuleFederationServerEnum({
    key: CommonEnvEnum.PRODUCTION_DEV.key,
    url: CommonEnvEnum.PRODUCTION_DEV.protocol + CommonEnvEnum.PRODUCTION_DEV.address,
    mode: CompileModeEnum.PRODUCTION.key,
  })
  static PRODUCTION_TEST = new ModuleFederationServerEnum({
    key: CommonEnvEnum.PRODUCTION_TEST.key,
    url: CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address,
    mode: CompileModeEnum.PRODUCTION.key,
  })
  static PRODUCTION_PREVIEW = new ModuleFederationServerEnum({
    key: CommonEnvEnum.PRODUCTION_PREVIEW.key,
    url: CommonEnvEnum.PRODUCTION_PREVIEW.protocol + CommonEnvEnum.PRODUCTION_PREVIEW.address,
    mode: CompileModeEnum.PRODUCTION.key,
  })
  static PRODUCTION_PROD = new ModuleFederationServerEnum({
    key: CommonEnvEnum.PRODUCTION_PROD.key,
    url: CommonEnvEnum.PRODUCTION_PROD.protocol + CommonEnvEnum.PRODUCTION_PROD.address,
    mode: CompileModeEnum.PRODUCTION.key,
  })
  static PRODUCTION_SQ = new ModuleFederationServerEnum({
    key: 'sq',
    url: 'http://192.168.101.139',
    mode: CompileModeEnum.PRODUCTION.key,
  })

  static DEVELOP_SQ = new ModuleFederationServerEnum({
    key: 'develop-sq',
    url: 'http://192.168.101.58',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static DEVELOP_YJY = new ModuleFederationServerEnum({
    key: 'develop-yjy',
    url: 'http://192.168.101.5',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static DEVELOP_LGH = new ModuleFederationServerEnum({
    key: 'develop-lgh',
    url: 'http://192.168.101.181',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static DEVELOP_YAH = new ModuleFederationServerEnum({
    key: 'develop-yah',
    url: 'http://192.168.101.167',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static DEVELOP_RSP = new ModuleFederationServerEnum({
    key: 'develop-rsp',
    url: 'http://192.168.101.173',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })
  static DEVELOP_TEMP = new ModuleFederationServerEnum({
    key: 'develop-temp',
    url: 'http://192.168.101.65',
    mode: CompileModeEnum.DEVELOPMENT.key,
  })

  static _ = this.closeEnum() // TypeScript: Color.closeEnum()
  constructor(props) {
    super()
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props))
  }

  static getEnumByKey(key) {
    if (key) {
      const index = this.enumValues.findIndex((item) => item.key === key)
      if (index > -1) {
        return this.enumValues[index]
      }
    }
    return ModuleFederationServerEnum.LOCAL
  }

  static getDevelopUrlByKey(key) {
    if (CommonEnvEnum.getProductionEnvList().includes(key)) {
      let link
      switch (key) {
        case ModuleFederationServerEnum.PRODUCTION_DEV.key:
          link = ModuleFederationServerEnum.PRODUCTION_DEV.url + ':8091'
          break
        case ModuleFederationServerEnum.PRODUCTION_TEST.key:
          link = ModuleFederationServerEnum.PRODUCTION_TEST.url + ':8091'
          break
      }
      return `${link}/web-v2/${key}-`
    } else if (!!key) {
      const index = this.enumValues.findIndex((item) => item.key === key)
      if (index > -1) {
        return this.enumValues[index].url
      } else {
        return ModuleFederationServerEnum.LOCAL.url
      }
    } else {
      return ModuleFederationServerEnum.LOCAL.url
    }
  }
  static getProductUrlByKey(key) {
    if (
      [ModuleFederationServerEnum.PRODUCTION_PREVIEW.key, ModuleFederationServerEnum.PRODUCTION_PROD.key].includes(key)
    ) {
      const index = this.enumValues.findIndex((item) => item.key === key)
      if (index > -1) {
        return this.enumValues[index].url
      }
    } else {
      const index = this.enumValues.findIndex((item) => item.key === key)
      if (index > -1) {
        return this.enumValues[index].url + ':8091/web-v2'
      }
    }
  }
}

module.exports = ModuleFederationServerEnum
