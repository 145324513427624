const camelCase = require('lodash/camelCase')
const kebabCase = require('lodash/kebabCase')

function packageName2ExposeName(projectName) {
  const temp = []
  projectName.split('_').forEach((item) => {
    temp.push(camelCase(item))
  })
  return temp.join('_')
}

function exposeName2PackageName(name) {
  const temp = []
  name.split('_').forEach((item) => {
    temp.push(kebabCase(item))
  })
  return temp.join('_')
}

function packageName2RootPath(packageName) {
  const packageArr = packageName.split('_')
  let rootPath = ''
  for (let i = 0; i < packageArr.length; i++) {
    if (i === 0) {
      rootPath = '/package.' + packageArr[i]
    } else if (i === packageArr.length - 1) {
      rootPath = rootPath + '/' + packageArr[i]
    } else {
      rootPath = rootPath + '/_' + packageArr[i]
    }
  }
  return rootPath
}

module.exports = { packageName2ExposeName, exposeName2PackageName, packageName2RootPath }
